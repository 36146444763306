import Repository from './repository'

const resource = '/v1/treinamentos/locais'

export default {
  buscaLocal (idDeficiencia) {
    return Repository.get(`${resource}/${idDeficiencia}`)
  },
  listarLocais () {
    return Repository.get(`${resource}`)
  },
  salvarLocal (deficiencia) {
    return Repository.post(`${resource}`, deficiencia)
  },
  atualizarLocal (idDeficiencia, deficiencia) {
    return Repository.put(`${resource}/${idDeficiencia}`, deficiencia)
  },
  deletarLocal (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  buscaLocalSala (idDeficiencia) {
    return Repository.get(`${resource}/salas/${idDeficiencia}`)
  },
  listarLocaisSala () {
    return Repository.get(`${resource}/salas`)
  },
  salvarLocalSala (deficiencia) {
    return Repository.post(`${resource}/salas`, deficiencia)
  },
  atualizarLocalSala (idDeficiencia, deficiencia) {
    return Repository.put(`${resource}/salas/${idDeficiencia}`, deficiencia)
  },
  deletarLocalSala (id) {
    return Repository.delete(`${resource}/salas/${id}`)
  }
}
