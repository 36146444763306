<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de sala</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<h3>{{sala.local.nome}}</h3>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.sala.sala.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="sala.sala" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.sala.sala.required">Campo obrigatório</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="nome" class="required">Descrição</label>
								<textarea class="form-control" v-model="sala.descricao" rows="3"></textarea>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import locaisTreinamentosRepository from './../../../../services/api/locaisTreinamentosRepository'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    sala: {
			sala: {
				required
			}
		}
  },
	data () {
		return {
			sala: {
				sala: '',
				local: {
					nome: ''
				}
      }
		}
	},
	created () {
		if (this.$route.query.id) {
			this.buscaSala(this.$route.query.id)
		} else if (this.$route.query.idLocal) {
			this.sala.local_id = this.$route.query.idLocal
		}
	},
	methods: {
		buscaSala (idSala) {
			locaisTreinamentosRepository.buscaLocalSala(idSala).then(response => {
				this.sala = response.data['data']
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'LocaisTreinamentos' })
    },
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.sala.id) {
					locaisTreinamentosRepository.atualizarLocalSala(this.sala.id, this.sala).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				} else {
					locaisTreinamentosRepository.salvarLocalSala(this.sala).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Sala cadastrada com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				}
			}
		}
	}
}
</script>

<style>

</style>